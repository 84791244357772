import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.png'
import { Flex, Box, Button } from 'rebass'
import { useTheme } from 'emotion-theming'
import { css } from '@emotion/core'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Navbar = () => {
  const theme = useTheme()
  return (
    <nav
      role="navigation"
      aria-label="main-navigation"
      css={css`
        background: ${theme.colors.header.bg};
        position: absolute;
        right: 0;
        left: 0;
      `}
    >
      <Box variant="container">
        <Flex
          height={{ _: 80, md: 96 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box maxWidth={{ _: 177, md: 238 }}>
            <Link to="/" title="Logo">
              <img src={logo} alt="GM Mobile Mechanics" />
            </Link>
          </Box>
          <Box>
            <Button
              as={OutboundLink}
              variant="outline"
              href="tel:07834230718"
              eventCategory="call_us_cta"
              eventLabel="Header call button"
              eventAction="Tap"
            >
              Call now<span className="screenReaderMobile">: 07834 230718</span>
            </Button>
          </Box>
        </Flex>
      </Box>
    </nav>
  )
}

export default Navbar
